module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"קיצור דרך","name":"קיצור דרך","description":"אפליקציה ללימוד תאוריית נהיגה","start_url":"/","background_color":"#f5f5f5","theme_color":"#3DC97C","display":"minimal-ui","icon":"src/images/logo.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2a579f5647eeb67ac28135d5fced9d8f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-amplitude-analytics/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"870a719648069cb7d6a325a7304efc16","head":false,"respectDNT":true,"exclude":[],"eventTypes":{"outboundLinkClick":"OutBoundLink","pageView":"PageView"},"amplitudeConfig":{"saveEvents":true,"includeUtm":true,"includeReferrer":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
